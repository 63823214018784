import { teamColor } from "@helpers/defaults";
import { convertJSONToArray, createElement, select } from "@utils/function";

export default function ShowSquadPoints(update = false) {
  if (!HQ.Properties.squads) return;

  const arrayObject = convertJSONToArray(HQ.Properties.squads);

  // update aquad scores
  if (update) {
    updateSquadScores(arrayObject);
    return;
  }

  (function addControlPlaceholders(map) {
    let corners = map._controlCorners;
    let l = "leaflet-";
    let container = map._controlContainer;

    function createCorner(vSide, hSide) {
      let className = l + vSide + " " + l + hSide + " container-lg";
      corners[vSide + hSide] = L.DomUtil.create("div", className, container);
    }

    createCorner("top", "center");

    L.DomEvent.disableClickPropagation(container);
  })(map);

  const configs = { position: "topcenter", description: "COURSE LAYER" };

  L.Control.Squads = L.Control.extend({
    options: {
      position: configs.position,
    },
    onAdd: function () {
      const leafletCenter = select(".leaflet-center");

      // squad wrapper
      const wrapperButtons = createElement("div");
      wrapperButtons.classList.add("squads-wrapper");
      wrapperButtons.classList.add("container");
      wrapperButtons.classList.add("gap-2");
      wrapperButtons.classList.add("flex-row-reverse");
      wrapperButtons.classList.add("justify-content-center");
      wrapperButtons.classList.add("d-flex");

      leafletCenter.insertAdjacentElement("beforeend", wrapperButtons);

      // logo
      // const logo = createElement("div");
      // logo.classList.add("squads-logo");
      // logo.insertAdjacentHTML(
      //   "afterbegin",
      //   '<img src="https://upload.wikimedia.org/wikipedia/en/7/76/Solheim_Cup-logo.png">'
      // );
      // wrapperButtons.insertAdjacentElement("beforeend", logo);

      // &#189; 1/2
      arrayObject.forEach(({ countryCode, name, shortname, points }, index) => {
        points = checkIsNumberIsInteger(points);

        const countrycode = countryCode.toLowerCase();
        const reverseRow =
          index === 0 ? "flex-row-reverse text-end" : "text-start";

        wrapperButtons.insertAdjacentHTML(
          "afterbegin",
          `<div class="row ${reverseRow} align-items-center justify-content-between team-color team-${countrycode}-score" style="--team-color: ${teamColor[countrycode].main.color}">
            <div class="col-auto team-name">
              <div class="team-shortname-name">${shortname}</div>
            </div>
            <div class="col-auto px-2 py-2 team-points team-points-${countrycode}">${points}</div>
          </div>`
        );
      });

      return wrapperButtons;
    },
  });

  new L.Control.Squads().addTo(map);
}

/**
 * Update score number
 *
 * @param {Array} squadScore
 */
function updateSquadScores(squadScore) {
  const teamPointsUSA = select(".team-points-usa");
  const teamPointsEUR = select(".team-points-eur");
  teamPointsUSA.textContent = "";
  teamPointsEUR.textContent = "";

  squadScore.forEach(({ countryCode, points }) => {
    if (countryCode == "USA") {
      teamPointsUSA.insertAdjacentHTML(
        "afterbegin",
        checkIsNumberIsInteger(points)
      );
    } else {
      teamPointsEUR.insertAdjacentHTML(
        "afterbegin",
        checkIsNumberIsInteger(points)
      );
    }
  });
}

/**
 * Check if number is integer if not add
 * 1/2 [&#189] to number and return string
 *
 * @param {Number} points
 * @returns String
 */
function checkIsNumberIsInteger(points) {
  return Number.isInteger(points) ? points : `${Math.floor(points)} &#189`;
}
