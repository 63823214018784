import { convertJSONToArray, isObjectEmpty, select } from "@utils/function";
import { PopupUniversal } from "@helpers/popup";
import ActiveInformation from "@components/sidebar/activeInformation";

/**
 * Show player when /locator?player=21
 * open popup
 */
export default function ShowPlayerPopup(flightsLayer) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const playerID = urlParams.get("player");

  if (!playerID) return;

  ActiveInformation({ player: true });

  const groupExistOnMap = map.hasLayer(window["flights"]);
  if (!isObjectEmpty(flightsLayer._layers) && !groupExistOnMap) {
    const informationCheckbox = select(`[data-layer-id="flights"]`);
    informationCheckbox?.click();
  }

  if (playerID) {
    let checkPlayer = false;
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (
          layer.options.icon.options.className.search(`player-${playerID}`) !==
            -1 &&
          !checkPlayer
        ) {
          layer.openPopup();
          checkPlayer = true;
        }
      }
    });

    if (!checkPlayer) {
      const playerInformation = showTeeTime(playerID);

      if (playerInformation.length <= 0) return;

      // get json player
      const playerInformationData = playerInformation[0];

      let { country, fullname, teetime } = playerInformationData;

      // check teetime
      const teetimeInfoText = teetime
        ? `<strong>Next tee time:</strong> ${teetime.slice(0, 5)}h`
        : "There is currently no tee time scheduled for this player";

      // prepering information fullname, flags and teetieInfo
      const template = `
      <div class="player pe-4">
        <div class="player-name pb-1"><strong>${fullname}</strong> <span title="${country}" class="flag-icon flag-icon-${country.toLowerCase()}"></span>
        </div>
        <div class="player-teetime">${teetimeInfoText}</div>
      </div>`;

      // show popup
      PopupUniversal({ template, width: 300 });
    }
  }
}

function showTeeTime(playerID) {
  const getAllPlayersArray = convertJSONToArray(
    HQ.Properties.layerFlights
  ).reduce((previousValue, currentValue) => {
    const { matchnumber, matchnumber_str, teetime } = currentValue;
    if (!previousValue.get(teetime)) {
      previousValue.set(matchnumber, {
        matchnumber,
        matchnumber_str,
        teetime,
        players: convertJSONToArray(currentValue.players),
      });
    } else previousValue.get(teetime)?.players?.push(currentValue);
    return previousValue;
  }, new Map());

  let newArray = [...getAllPlayersArray.values()].reduce((previus, current) => {
    const { matchnumber, matchnumber_str, teetime } = current;
    current.players.map((player) => {
      if (Number(player.id) === Number(playerID)) {
        player.matchnumber = matchnumber;
        player.matchnumber_str = matchnumber_str;
        player.teetime = teetime;
        previus.push(player);
      }
    });
    return previus;
  }, []);

  return newArray;
}
