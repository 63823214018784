import { select, body } from "@utils/function";

/**
 * Clear active slider and remove active class
 */
export default function CleareActiveSlider() {
  const activeSliderClassName = "active-slider";
  const activeSliderMenuClassName = "active-slider-menu";

  const activeSlider = select(`.${activeSliderClassName}`);
  activeSlider?.classList.remove(activeSliderClassName);

  const activeSliderMenu = select(`.${activeSliderMenuClassName}`);
  activeSliderMenu?.classList.remove(activeSliderMenuClassName);

  const leaderboardContent = activeSlider?.classList.contains(
    "leaderboard-content"
  );

  if (leaderboardContent) {
    const sliderContent = select(".leaderboard-content > .slider-content");
    sliderContent.textContent = "";
  }

  const sliders = [
    "information-is-active",
    "players-is-active",
    "leaderboard-is-active",
    "location-is-active-error",
    "location-is-active",
    "highlights-is-active",
    "programme-is-active",
    "schedule-is-active",
    "results-is-active",
    "matchplay-players-is-active",
    "matchplay-leaderboard-is-active",
  ];

  sliders.forEach((slider) => {
    body.classList.remove(slider);
  });
}
