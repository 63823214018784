import {
  convertJSONToArray,
  createElement,
  getItemLocalStorage,
  markFavoritePlayers,
  objectLength,
  removeIdFromLocalStorage,
  scoreNumberHtmlElement,
  select,
  selectAll,
  setItemLocalStorage,
  svgIcon,
} from "@utils/function";
import { firstRoundHeader, nextRoundsHeader } from "@helpers/defaults";
import AnalyticsTracking from "@helpers/analyticsTracking";

const checkCut = typeof HQ.Properties.cut !== "undefined" ? true : false;
let countCutRow = 0;

const LeaderboardContentSlider = select(".leaderboard-content .slider-content");
const storageName = "HQfavoritePlayers";

// check if currentRound var exist
let checkCurrentRound =
  typeof HQ.Properties.currentRound !== "undefined" ? true : false;

// console.log("HQ.Properties.top5", HQ.Properties.top5);
// console.log("HQ.Properties.layerFlights", HQ.Properties.layerFlights);

function LeaderboardCreateRow(type) {
  let checkCurrentRound =
    typeof HQ.Properties.currentRound !== "undefined" ? true : false;

  const templateHead =
    checkCurrentRound && +HQ.Properties.currentRound > 1
      ? createHeader(nextRoundsHeader, "nextRounds")
      : createHeader(firstRoundHeader, "firstRound");

  // currentRound = +currentRound;
  // console.log("checkCurrentRound", checkCurrentRound);

  const top5 = HQ.Properties.top5;
  if (type === "update") {
    LeaderboardContentSlider.textContent = "";
  }

  let only5 = convertJSONToArray(top5);

  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");

  // get only fav players from only5 array
  // add fav: true element
  const favPlayers = only5
    .filter((player) => getLocalStorage?.includes(player.id))
    .map((item) => {
      return { ...item, fav: true };
    });

  // marging favPlayers array with only5 array
  only5 = getLocalStorage ? [...favPlayers, ...only5] : only5;

  const wrapper = createElement("div");
  wrapper.classList.add("top-five-wrapper");
  wrapper.classList.add("container");

  // add header row to wrapper
  wrapper.insertAdjacentHTML("afterbegin", templateHead);

  only5.forEach((element, index) => {
    let {
      id,
      fav,
      proam,
      position,
      position_str,
      country,
      firstname,
      lastname,
      totaltopar,
      totaltopar_str,
      rounds,
    } = element;

    if (proam) {
      proam = proam === "am" ? "(A)" : "";
    } else {
      proam = "";
    }

    // get total html elements
    const totalColumn = totaltoparInformation(totaltopar, totaltopar_str);

    // find ["NSY" - "TEE" - "PLAYING" - "FINISHED"],  in array HQ.Properties.layerFlights
    let { status, matchnumber_str, score, currenthole, startingtee } =
      findLeaderboardInformation(id);

    status = typeof status !== "undefined" ? status : "";

    // const todayColumn = rounds[currentRound].score;
    let totaltoparData = totaltoparInformation(totaltopar, totaltopar_str);
    let todayColumn = checkCurrentRound
      ? +HQ.Properties.currentRound > 1
        ? rounds[HQ.Properties.currentRound]?.score
        : totaltoparData !== null
        ? totaltoparData
        : ""
      : "";

    const thrucolumnrounds = thruColumnRounds(rounds);

    const thruColumn =
      typeof thrucolumnrounds !== "undefined" ? thruColumnRounds(rounds) : "";

    // add color for rows
    const colorRow = index % 2 === 0 ? "bg-light" : "bg-white";

    // diffrent border-color for rows
    let favPlayers = fav ? 'data-fav="true"' : "";

    let flightnumber = "";
    let iconPlayer = "";
    if (checkCurrentRound && (status === "PLAYING" || status === "TEE")) {
      flightnumber = `data-id-flightnumber="${matchnumber_str}"`;
      iconPlayer = `<span class="player-icon">${svgIcon({
        color: "#3e3e3e",
        name: "icon-flights",
      })}</span>`;
    }

    // check previus position === current position
    // dont show number
    let previusPosition = only5[index - 1]?.position_str;

    // check previus is favorites
    let previusPositionFav = only5[index - 1]?.fav;

    // show only one latter - firstname
    let firstName = firstname != undefined ? `${firstname} ` : "";

    firstname = firstname != undefined ? `${firstname.slice(0, 1)}.` : "";
    lastname = lastname != null ? lastname : "";

    let playerName = `${firstname} ${lastname} ${proam} ${iconPlayer}`;
    let dataPlayerName = `data-full-name="${firstName}${lastname}"`;

    // const pos = position === previusPosition ? "" : position || "-";

    const pos = fav
      ? position_str !== previusPosition
        ? position_str
        : ""
      : position_str === previusPosition && !previusPositionFav
      ? ""
      : position_str || "-";

    let showCutRow = false;
    let cutNumber = "";
    let cutRow = "";

    if (checkCut) {
      if (
        (HQ.Properties.cut || HQ.Properties.cut === 0) &&
        totaltopar > HQ.Properties.cut &&
        only5[index - 1]?.totaltopar === HQ.Properties.cut
      ) {
        countCutRow++;
        showCutRow = true;
        if (HQ.Properties.cut < 0) {
          cutNumber = HQ.Properties.cut;
        } else if (HQ.Properties.cut === 0) {
          cutNumber = "PAR";
        } else if (HQ.Properties.cut > 0) {
          cutNumber = "+" + HQ.Properties.cut;
        }
      }

      cutRow = `<div class="projected-cut py-1 text-white">PROJECTED CUT ${cutNumber}</div>`;
    }

    const wrapperRow = `<div ${dataPlayerName} ${favPlayers} ${flightnumber} data-id=${id} class="d-flex align-items-center row row-item py-1 ${colorRow}">`;

    const posCol = `<div class="p-1 col-1 text-center top-five-position">${pos}</div>`;

    const columnNumberNamePlayer = HQ.Properties.currentRound > 1 ? 5 : 7;

    const playerNameCol = `<div class="p-1 col-${columnNumberNamePlayer} top-five-fullname">${playerName}</div>`;

    const flagCol = `<div class="p-1 col-1 text-center top-five-country"><span title="${country}" class="flag-icon flag-icon-${country?.toLowerCase()}"></span></div>`;

    const truColumnCol = `<div class="p-1 col-1 text-center small-text top-five-thru">${thruColumn}</div>`;

    const iconStarCol = `<div class="p-1 col-1 text-center top-fav"><div class="icon-star">${svgIcon(
      { name: "icon-star" }
    )}</div></div>`;

    const todayCol = `<div class="p-2 col-1 text-center small-text top-five-total">${
      todayColumn != null ? todayColumn : ""
    }</div>`;

    const totalCol =
      checkCurrentRound && +HQ.Properties.currentRound > 1
        ? `<div class="p-1 col-2 text-center top-five-total">${
            totalColumn ? totalColumn : ""
          }</div>`
        : "";

    const slotRowsAds =
      HQ.Properties.showAds == "1" && generateSlotForAds(index)
        ? generateSlotForAds(index)
        : "";

    const firstRound = `
        ${wrapperRow}
        ${posCol}
        ${playerNameCol}
        ${flagCol}
        ${todayCol}
        ${truColumnCol}
        ${iconStarCol}
      </div>
      ${slotRowsAds}
    `;

    // template for next rounds [2,3...]

    const cutRowElement = countCutRow < 2 ? cutRow : "";

    const nextRounds = `
        ${showCutRow && !fav ? cutRowElement : ""}
        ${wrapperRow}
        ${posCol}
        ${playerNameCol}
        ${flagCol}
        ${todayCol}
        ${totalCol}
        ${truColumnCol}
        ${iconStarCol}
      </div>
      ${slotRowsAds}
    `;

    const templateRow =
      checkCurrentRound && +HQ.Properties.currentRound > 1
        ? nextRounds
        : firstRound;

    // don't show leader board
    if (HQ.Properties.isSocialMediaBot) return;
    wrapper.insertAdjacentHTML("beforeend", templateRow);
  });

  return wrapper;
}

function thruColumnRounds(round) {
  if (!checkCurrentRound) return;

  // console.log(round, currentRound);
  HQ.Properties.currentRound = +HQ.Properties.currentRound;

  let stat = "";

  if (typeof round[HQ.Properties.currentRound] === "undefined") {
    stat = "";
  } else {
    let { status, holesplayed, startingtee, teetime } =
      round[HQ.Properties.currentRound];

    status = status === null ? "" : status;

    let $holesPlayed = null;
    let $startingTeeStar = "";
    let $startingTee = null;
    let $teetime = null;

    $holesPlayed = holesplayed;
    // Show a * for all matches not starting from Tee 1
    $startingTee = startingtee;
    if ($startingTee != 1) {
      $startingTeeStar = "*";
    }

    $teetime = teetime ? teetime.slice(0, 5) : "";

    if (Number(status) === 2) {
      stat = "F";
    } else if (Number(status) === 1) {
      if (teetime) {
        stat = `${$teetime}${$startingTeeStar}`;
      } else {
        stat = "";
      }
    } else {
      stat = `${$holesPlayed}${$startingTeeStar}`;
    }
  }

  return stat.trim();
}

/**
 * Find information in array HQ.Properties.layerFlights
 * depending on player id
 *
 * @param {number} id
 * @returns object
 */
function findLeaderboardInformation(id) {
  // console.log("playerID", id);
  // console.log("HQ.Properties.layerFlights", convertJSONToArray(HQ.Properties.layerFlights));

  const arrayLyaerFlights = convertJSONToArray(HQ.Properties.layerFlights);

  // console.log("HQ.Properties.layerFlights", arrayLyaerFlights);

  let flighsInformation = {};

  arrayLyaerFlights.forEach(
    ({
      status,
      players,
      score,
      teetime,
      holesplayed,
      matchnumber_str,
      startingtee,
      currenthole,
    }) => {
      // convert player to array
      const arrayPlayers = convertJSONToArray(players);

      // build object with status, startingtee, currenthole
      arrayPlayers.forEach((player) => {
        if (player.id === id) {
          flighsInformation.status = status;
          flighsInformation.score = score;
          flighsInformation.teetime = teetime;
          flighsInformation.matchnumber_str = matchnumber_str;
          flighsInformation.holesplayed = holesplayed;
          flighsInformation.startingtee = startingtee;
          flighsInformation.currenthole = currenthole;
        }
      });
    }
  );

  // console.log(flighsInformation);
  // return object with status, startingtee, currenthole
  return flighsInformation;
}

/**
 *
 * @param {string} totaltopar
 * @param {string} totaltopar_str
 */
function totaltoparInformation(totaltopar, totaltopar_str) {
  return totaltopar
    ? totaltopar < 0
      ? scoreNumberHtmlElement("danger", totaltopar_str)
      : scoreNumberHtmlElement("success", totaltopar_str)
    : totaltopar_str;
}

/**
 *
 * @param {array} arrayRest
 * @param {string} rest
 * @returns html
 */
function createHeader(arrayRest, rest) {
  const padding = rest === "firstRound" ? "p-2 " : "p-1 ";

  const headerRows = arrayRest
    .map(({ classNames, i18n }, index) => {
      const hqPropertiesI18n = HQ.Properties.i18n["leaderboardHeader"][i18n];

      const headTitle = hqPropertiesI18n !== undefined ? hqPropertiesI18n : "";
      const iconPlayer =
        index === 1
          ? `<span class="d-flex player-name-info">(<span class="player-icon-header">${svgIcon(
              { color: "#fff", name: "icon-flights" }
            )}</span> = playing)</span>`
          : "";
      return `
      <div class="${padding}${classNames}">
        <span>${headTitle}</span> ${iconPlayer}
      </div>`;
    })
    .join("");

  const fullTemplate = `<div class="row top-five-head py-1">${headerRows}</div>`;

  return fullTemplate;
}

// it top5 is not empty
// if (
//   typeof HQ.Properties.top5 !== "undefined" &&
//   objectLength(HQ.Properties.top5) > 0
// ) {
//   LeaderboardContentSlider?.insertAdjacentElement(
//     "beforeend",
//     LeaderboardCreateRow()
//   );
// }

let selectedPlayers = [];

/**
 * Create row for leaderboard width favorite players
 */
function LeaderBoardAddFavoritePlayer() {
  const topFiveDataID = selectAll(".top-five-wrapper > [data-id]");

  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");

  // add active row to favorite players
  [].slice
    .call(topFiveDataID)
    .filter((player) => getLocalStorage?.includes(+player.dataset.id))
    .map((item) => item.classList.add("active-row"));

  topFiveDataID.forEach((player) => {
    player.addEventListener("click", (e) => {
      const target = e.target;

      if (!target.classList.contains("icon-star")) return;

      // activeGroupIsideInformation(true);

      const row = target.closest(".row-item");
      // click on active row
      if (row.classList.contains("active-row")) {
        const id = row.dataset.id;

        const getAllID = selectAll(`div[data-id="${id}"]`);
        getAllID.forEach((item) => {
          // if row is data-fav remove row
          if (item.hasAttribute("data-fav")) {
            item.remove();
          } else {
            item.classList.remove("active-row");
          }
        });

        // remove id from localStorage
        removeIdFromLocalStorage(id, storageName);

        // hilight flightsmark the favored players
        markFavoritePlayers();

        // scrollToTopContainer();
      } else {
        // get array from localStorage
        const getLocalStorage = getItemLocalStorage(storageName);
        // get id from row
        const id = row.dataset.id;

        selectedPlayers = [];

        if (getLocalStorage) {
          // if local storage exist add id to array
          selectedPlayers.push(...new Set(getLocalStorage));
        }
        // add id to array
        selectedPlayers.push(+id);

        // add class active-row to row when click
        row.classList.add("active-row");

        // remove duplicates from array
        const newPlayers = [...new Set(selectedPlayers)];

        // create localStorage with new array
        setItemLocalStorage(storageName, newPlayers);

        // add analytics when press icon-star to favorite
        AnalyticsTracking("setFavorite", { name: player.dataset.fullName });

        // hilight flightsmark the favored players
        markFavoritePlayers();

        // scrollToTopContainer();
      }
    });
  });
}

/**
 * Cenerate row "slot" for ads
 *
 * @param {Number} index
 */
function generateSlotForAds(index) {
  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");

  // const rows = [
  //   { id: 10, slot: 1 },
  //   { id: 25, slot: 2 },
  //   { id: 45, slot: 3 },
  //   { id: 66, slot: 1, idx: 2 },
  //   { id: 100, slot: 2, idx: 2 },
  // ];

  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/350
  const rows = [
    { id: 5, slot: 1 },
    { id: 10, slot: 1, idx: 1 },
    { id: 25, slot: 1, idx: 2 },
    { id: 45, slot: 1, idx: 3 },
    { id: 66, slot: 1, idx: 4 },
  ];

  const localStorageLength = getLocalStorage?.length ?? 0;

  const newRows = rows
    .filter((obj) => obj.id + localStorageLength === index + 1)
    .map(({ slot, idx }) => {
      const index = idx ? `-index-${idx}` : "";
      return `
      <aside class="advertisement">
        <div class="advertisement__container">
          <div data-base-slot="${slot}" id="pb-slot-leader-${slot}${index}" class="ads-row text-center"></div>
        </div>
      </aside>`;
    });

  return newRows[0];
}

export {
  LeaderboardCreateRow,
  LeaderBoardAddFavoritePlayer,
  LeaderboardContentSlider,
};
