import {
  convertJSONToArray,
  createElement,
  objectLength,
  select,
} from "@utils/function";

export default function MachplayLeaderBoard(update = false) {
  const matchplayLeaderBoardContent = select(
    ".matchplay-leaderboard-content .slider-content"
  );

  matchplayLeaderBoardContent.textContent = "";

  const teamsArray = HQ.Properties.matches;

  // reverse object issue in laravel
  Object.entries(teamsArray)
    .reverse()
    .forEach(([key, value], index) => {
      const arrayPlayers = convertJSONToArray(value);
      const roundName = arrayPlayers[0].roundName;

      const templateHead = createElement("div");
      templateHead.classList.add("leaderboards-head");
      templateHead.classList.add("p-2");
      templateHead.classList.add("text-center");
      templateHead.classList.add(`round-${key}`);
      templateHead.textContent = `${HQ.Properties.i18n.slider.round} ${key} - ${roundName}`;

      matchplayLeaderBoardContent.insertAdjacentElement(
        "beforeend",
        templateHead
      );

      matchplayLeaderBoardContent.insertAdjacentHTML(
        "beforeend",
        `<div class="wrapper-${key} conrainer"></div>`
      );

      arrayPlayers.forEach((obj) => {
        let {
          leader,
          standing,
          holesplayed,
          status,
          isActive,
          opponent_firstname,
          opponent_lastname,
          opponentpartner_firstname,
          opponentpartner_lastname,
          partner_firstname,
          partner_lastname,
          player_firstname,
          player_lastname,
          matchnumber_str,
        } = obj;

        const classIsActive = isActive ? "color-reverse" : "color";
        const cursorPointer = isActive ? "cursor-pointer" : "";

        if (leader === 2) {
          leader = `<div class="color-standing ${classIsActive}-usa">${standing}</div>`;
        } else if (leader === 1) {
          leader = `<div class="color-standing ${classIsActive}-eur">${standing}</div>`;
        } else {
          leader = `<div class="color-standing ${classIsActive}-as">${standing}</div>`;
        }

        const dataIdFlightnumber = matchnumber_str
          ? `data-id-flightnumber=${matchnumber_str}`
          : "";

        const opponent = opponent_firstname
          ? `<span>${opponent_firstname} ${opponent_lastname}</span>`
          : "";

        const opponentPartner = opponentpartner_firstname
          ? `<span>${opponentpartner_firstname} ${opponentpartner_lastname}</span>`
          : "";

        const partner = partner_firstname
          ? `<span>${partner_firstname} ${partner_lastname}</span>`
          : "";

        const player = player_firstname
          ? `<span>${player_firstname} ${player_lastname}</span>`
          : "";

        // -------------------------------------------------------

        let dataFullName;
        if (player_firstname) {
          dataFullName = `${player_firstname} ${player_lastname}`;
        }

        if (partner_firstname) {
          dataFullName += `, ${partner_firstname} ${partner_lastname}`;
        }

        if (opponent_firstname) {
          dataFullName += `, ${opponent_firstname} ${opponent_lastname}`;
        }
        if (opponentpartner_firstname) {
          dataFullName += `, ${opponentpartner_firstname} ${opponentpartner_lastname}`;
        }

        dataFullName = `data-full-name="${dataFullName}"`;

        // -------------------------------------------------------

        const thru =
          status === "FIN" ? `FIN ${holesplayed}` : `thru ${holesplayed}`;

        const templateLeaderBoard = `
        <div class="container wrapper border-bottom ${cursorPointer}" ${dataIdFlightnumber} ${dataFullName} data-match-play="true">
          <div class="row my-2">
            <div class="col-1 p-0 flag-usa align-self-center">
              <span title="USA" class="flag-icon flag-icon-match flag-icon-usa"></span>
            </div>
            <div class="d-flex col-4 flex-column text-center team-person team-lead-usa align-self-center">
              ${opponent}
              ${opponentPartner}
            </div>
            <div class="col-2 p-0 d-flex flex-column text-center align-self-center team-leader">
              <div class="team-leader-up">${leader}</div>
              <div class="team-thru">${thru}</div>
            </div>
            <div class="d-flex col-4 flex-column text-center team-person team-lead-eur align-self-center">
              ${player}
              ${partner}
            </div>
            <div class="col-1 p-0 flag-eur align-self-center">
              <span title="EUR" class="flag-icon flag-icon-match flag-icon-eur"></span>
            </div>
          </div>
        </div>
      `;

        const round = select(`.wrapper-${key}`);

        round.insertAdjacentHTML("beforeend", templateLeaderBoard);
      });
    });
}
